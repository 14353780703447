var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',{staticClass:"mt-4"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',{staticClass:"py-6"},[_c('v-container',[_c('p',[(!_vm.isOwner)?_c('span',{staticClass:"red--text"},[_vm._v(" Apenas a empresa criadora do pedido pode editar. ")]):_vm._e()]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.empresaAtual.gate)?_c('v-row',{staticClass:"mb-0 pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"gate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Gate/Unidade Destino","required":"","items":_vm.gates,"item-text":_vm.getLabelEmpresa,"item-value":"public_id","return-object":"","error-messages":errors},on:{"change":_vm.setFornecedorPedido},model:{value:(_vm.pedido.gate),callback:function ($$v) {_vm.$set(_vm.pedido, "gate", $$v)},expression:"pedido.gate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"U.F","disabled":"","error-messages":errors},model:{value:(_vm.pedido.gate.state),callback:function ($$v) {_vm.$set(_vm.pedido.gate, "state", $$v)},expression:"pedido.gate.state"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"município"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Município","disabled":"","error-messages":errors},model:{value:(_vm.pedido.gate.city),callback:function ($$v) {_vm.$set(_vm.pedido.gate, "city", $$v)},expression:"pedido.gate.city"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',{staticClass:"mb-0 pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"data_inicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Data Inicio","required":"","error-messages":errors,"type":"date"},model:{value:(_vm.pedido.data_inicio),callback:function ($$v) {_vm.$set(_vm.pedido, "data_inicio", $$v)},expression:"pedido.data_inicio"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"data_fim","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Data Fim","required":"","error-messages":errors,"type":"date"},model:{value:(_vm.pedido.data_fim),callback:function ($$v) {_vm.$set(_vm.pedido, "data_fim", $$v)},expression:"pedido.data_fim"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[(!_vm.temPedidoASerEditado)?_c('validation-provider',{attrs:{"name":"fornecedor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.empresaAtual.gate)?_c('v-autocomplete',{attrs:{"label":"Fornecedor","required":"","items":_vm.fornecedores,"item-text":_vm.getLabelEmpresa,"item-value":"public_id","return-object":"","error-messages":errors,"disabled":!_vm.empresaAtual.gate,"readonly":!_vm.empresaAtual.gate},on:{"change":_vm.handleFornecedorSelecionado},model:{value:(_vm.pedido.fornecedor),callback:function ($$v) {_vm.$set(_vm.pedido, "fornecedor", $$v)},expression:"pedido.fornecedor"}}):_c('v-text-field',{attrs:{"label":"Fornecedor","disabled":""},model:{value:(_vm.pedido.fornecedor.trade_name),callback:function ($$v) {_vm.$set(_vm.pedido.fornecedor, "trade_name", $$v)},expression:"pedido.fornecedor.trade_name"}})]}}],null,true)}):_c('v-text-field',{attrs:{"label":"Fornecedor","disabled":""},model:{value:(_vm.pedido.fornecedor.trade_name),callback:function ($$v) {_vm.$set(_vm.pedido.fornecedor, "trade_name", $$v)},expression:"pedido.fornecedor.trade_name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"U.F","disabled":"","error-messages":errors},model:{value:(_vm.pedido.fornecedor.state),callback:function ($$v) {_vm.$set(_vm.pedido.fornecedor, "state", $$v)},expression:"pedido.fornecedor.state"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"município"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Município","disabled":"","error-messages":errors},model:{value:(_vm.pedido.fornecedor.city),callback:function ($$v) {_vm.$set(_vm.pedido.fornecedor, "city", $$v)},expression:"pedido.fornecedor.city"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mb-0 pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"tipo de frete","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Tipo de frete","required":"","items":_vm.tiposFrete,"item-text":"descricao","item-value":"id","error-messages":errors},model:{value:(_vm.pedido.tipoFrete),callback:function ($$v) {_vm.$set(_vm.pedido, "tipoFrete", $$v)},expression:"pedido.tipoFrete"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"navio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Navio","items":_vm.naviosComOpcaoNenhum,"item-text":"descricao","item-value":"id","error-messages":errors},on:{"change":_vm.getDistribuicoes},model:{value:(_vm.pedido.navio),callback:function ($$v) {_vm.$set(_vm.pedido, "navio", $$v)},expression:"pedido.navio"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"nº pedido importação","rules":{ required: _vm.navioFoiSelecionado }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nº Pedido Importação","title":"Número do pedido da importação","error-messages":errors},model:{value:(_vm.pedido.numero_pedido_importacao),callback:function ($$v) {_vm.$set(_vm.pedido, "numero_pedido_importacao", $$v)},expression:"pedido.numero_pedido_importacao"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"produto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Produto","required":"","items":_vm.produtos,"item-text":"descricao","item-value":"id","return-object":"","error-messages":errors},model:{value:(_vm.pedido.produto),callback:function ($$v) {_vm.$set(_vm.pedido, "produto", $$v)},expression:"pedido.produto"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"sub produto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Sub Produto","required":"","items":_vm.subProdutos,"item-text":"descricao","item-value":"id","error-messages":errors},model:{value:(_vm.pedido.subProduto),callback:function ($$v) {_vm.$set(_vm.pedido, "subProduto", $$v)},expression:"pedido.subProduto"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"embalagem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Embalagem","required":"","items":_vm.embalagens,"item-text":"descricao","item-value":"id","error-messages":errors},model:{value:(_vm.pedido.embalagem),callback:function ($$v) {_vm.$set(_vm.pedido, "embalagem", $$v)},expression:"pedido.embalagem"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mb-0 pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Tipo de Tolerância","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Tipo de Tolerância","item-text":"name","required":"","item-value":"id","items":_vm.tiposTolerancia,"error-messages":errors},model:{value:(_vm.pedido.tipo_tolerancia),callback:function ($$v) {_vm.$set(_vm.pedido, "tipo_tolerancia", $$v)},expression:"pedido.tipo_tolerancia"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"tolerância mínima","rules":{
                      min_value:
                        _vm.regras_tolerancia[_vm.pedido.tipo_tolerancia].min,
                      max_value:
                        _vm.regras_tolerancia[_vm.pedido.tipo_tolerancia].max,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","min":_vm.regras_tolerancia[_vm.pedido.tipo_tolerancia].min,"max":_vm.regras_tolerancia[_vm.pedido.tipo_tolerancia].max,"label":("Tolerância " + (_vm.getNameById(
                        _vm.pedido.tipo_tolerancia
                      )) + " Para Abaixo"),"error-messages":errors,"disabled":!_vm.pedido.tipo_tolerancia},model:{value:(_vm.pedido.tolerancia_min),callback:function ($$v) {_vm.$set(_vm.pedido, "tolerancia_min", $$v)},expression:"pedido.tolerancia_min"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"tolerância máxima","rules":{
                      min_value:
                        _vm.regras_tolerancia[_vm.pedido.tipo_tolerancia].min,
                      max_value:
                        _vm.regras_tolerancia[_vm.pedido.tipo_tolerancia].max,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","min":_vm.regras_tolerancia[_vm.pedido.tipo_tolerancia].min,"max":_vm.regras_tolerancia[_vm.pedido.tipo_tolerancia].max,"label":("Tolerância " + (_vm.getNameById(
                        _vm.pedido.tipo_tolerancia
                      )) + " Para Cima"),"error-messages":errors,"disabled":!_vm.pedido.tipo_tolerancia},model:{value:(_vm.pedido.tolerancia_max),callback:function ($$v) {_vm.$set(_vm.pedido, "tolerancia_max", $$v)},expression:"pedido.tolerancia_max"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Tipo de Operação","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Tipo de Operação","item-text":"descricao","required":"","item-value":"id","items":_vm.tiposOperacao,"error-messages":errors},model:{value:(_vm.pedido.tipo_operacao),callback:function ($$v) {_vm.$set(_vm.pedido, "tipo_operacao", $$v)},expression:"pedido.tipo_operacao"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mb-0 pb-0"},[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"4"}},[_c('validation-provider',{attrs:{"name":"quantidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Quantidade Total (Ton)","rules":_vm.rules,"min":_vm.pedidoASerEditado.quantidade_usada,"error-messages":errors},model:{value:(_vm.pedido.quantidade),callback:function ($$v) {_vm.$set(_vm.pedido, "quantidade", $$v)},expression:"pedido.quantidade"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"type":"number","label":"Quantidade Usada (Ton)","disabled":true},model:{value:(_vm.pedidoASerEditado.quantidade_usada),callback:function ($$v) {_vm.$set(_vm.pedidoASerEditado, "quantidade_usada", $$v)},expression:"pedidoASerEditado.quantidade_usada"}})],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('buttons-cadastro',{attrs:{"disabled":invalid || !_vm.isOwner},on:{"close":_vm.close,"save":_vm.save}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }