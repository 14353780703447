import {
  MyIntegerEnum,
  getById as _getById,
  getNameById as _getNameById,
} from './enum'

const Tolerancia = {
  Perc: MyIntegerEnum.create(1, 'Percentual (%)'),
  Bruto: MyIntegerEnum.create(2, 'Bruto (Kg)'),
}

const getById = (id) => {
  return _getById(Tolerancia, id)
}
const getNameById = (id) => {
  return _getNameById(Tolerancia, id)
}

export { Tolerancia, getById, getNameById }
