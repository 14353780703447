<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <suspensao
      :activator="false"
      :permission="permission"
      model="Pedido"
      :item="pedidoSuspensao"
      ref="suspensao"
    >
    </suspensao>
    <div class="mt-4">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-card-text class="py-6">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row class="mb-0 pb-0" v-if="!empresaAtual.gate">
                  <v-col cols="12" sm="12" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="gate"
                      rules="required"
                    >
                      <v-autocomplete
                        label="Gate/Unidade Destino"
                        required
                        :items="gates"
                        v-model="pedido.gate"
                        :item-text="getLabelEmpresa"
                        item-value="public_id"
                        return-object
                        :error-messages="errors"
                        @change="setFornecedorPedido"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <validation-provider v-slot="{ errors }" name="estado">
                      <v-text-field
                        label="U.F"
                        v-model="pedido.gate.state"
                        disabled
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <validation-provider v-slot="{ errors }" name="município">
                      <v-text-field
                        label="Município"
                        v-model="pedido.gate.city"
                        disabled
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row class="mb-0 pb-0">
                  <v-col cols="12" sm="12" md="2">
                    <validation-provider
                      v-slot="{ errors }"
                      name="data_inicio"
                      rules="required"
                    >
                      <v-text-field
                        v-model="pedido.data_inicio"
                        label="Data Inicio"
                        required
                        :error-messages="errors"
                        type="date"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <validation-provider
                      v-slot="{ errors }"
                      name="data_fim"
                      rules="required"
                    >
                      <v-text-field
                        v-model="pedido.data_fim"
                        label="Data Fim"
                        required
                        :error-messages="errors"
                        type="date"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <validation-provider
                      v-if="!temPedidoASerDistribuido"
                      v-slot="{ errors }"
                      name="fornecedor"
                      rules="required"
                    >
                      <v-autocomplete
                        v-if="empresaAtual.gate"
                        label="Fornecedor"
                        required
                        :items="fornecedores"
                        v-model="pedido.fornecedor"
                        :item-text="getLabelEmpresa"
                        item-value="public_id"
                        return-object
                        :error-messages="errors"
                        :disabled="!empresaAtual.gate"
                        :readonly="!empresaAtual.gate"
                        @change="handleFornecedorSelecionado"
                      />
                      <v-text-field
                        v-else
                        label="Fornecedor"
                        disabled
                        v-model="pedido.fornecedor.trade_name"
                      />
                    </validation-provider>
                    <v-text-field
                      v-else
                      label="Fornecedor"
                      v-model="pedido.fornecedor.trade_name"
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <validation-provider v-slot="{ errors }" name="estado">
                      <v-text-field
                        label="U.F"
                        v-model="pedido.fornecedor.state"
                        disabled
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <validation-provider v-slot="{ errors }" name="município">
                      <v-text-field
                        label="Município"
                        v-model="pedido.fornecedor.city"
                        disabled
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row class="mb-0 pb-0">
                  <v-col cols="12" sm="12" md="2">
                    <validation-provider
                      v-slot="{ errors }"
                      name="tipo de frete"
                      rules="required"
                    >
                      <v-autocomplete
                        label="Tipo de frete"
                        required
                        :items="tiposFrete"
                        v-model="pedido.tipoFrete"
                        item-text="descricao"
                        item-value="id"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <validation-provider v-slot="{ errors }" name="navio">
                      <v-autocomplete
                        label="Navio"
                        :items="naviosComOpcaoNenhum"
                        v-model="pedido.navio"
                        item-text="descricao"
                        item-value="id"
                        :error-messages="errors"
                        @change="getDistribuicoes"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <validation-provider
                      v-slot="{ errors }"
                      name="nº pedido importação"
                      :rules="{ required: navioFoiSelecionado }"
                    >
                      <v-text-field
                        v-model="pedido.numero_pedido_importacao"
                        label="Nº Pedido Importação"
                        title="Número do pedido da importação"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <validation-provider
                      v-slot="{ errors }"
                      name="produto"
                      rules="required"
                    >
                      <v-autocomplete
                        label="Produto"
                        required
                        :items="produtos"
                        v-model="pedido.produto"
                        item-text="descricao"
                        item-value="id"
                        return-object
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <validation-provider
                      v-slot="{ errors }"
                      name="sub produto"
                      rules="required"
                    >
                      <v-autocomplete
                        label="Sub Produto"
                        required
                        :items="subProdutos"
                        v-model="pedido.subProduto"
                        item-text="descricao"
                        item-value="id"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="2">
                    <validation-provider
                      v-slot="{ errors }"
                      name="embalagem"
                      rules="required"
                    >
                      <v-autocomplete
                        label="Embalagem"
                        required
                        :items="embalagens"
                        v-model="pedido.embalagem"
                        item-text="descricao"
                        item-value="id"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row class="mb-0 pb-0">
                  <v-col cols="12" sm="12" md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Tipo de Tolerância"
                      rules="required"
                    >
                      <v-autocomplete
                        label="Tipo de Tolerância"
                        item-text="name"
                        required
                        item-value="id"
                        :items="tiposTolerancia"
                        v-model="pedido.tipo_tolerancia"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="tolerância mínima"
                      :rules="{
                        min_value:
                          regras_tolerancia[pedido.tipo_tolerancia].min,
                        max_value:
                          regras_tolerancia[pedido.tipo_tolerancia].max,
                      }"
                    >
                      <v-text-field
                        type="number"
                        :min="regras_tolerancia[pedido.tipo_tolerancia].min"
                        :max="regras_tolerancia[pedido.tipo_tolerancia].max"
                        :label="`Tolerância ${getNameById(
                          pedido.tipo_tolerancia
                        )} Para Abaixo`"
                        v-model="pedido.tolerancia_min"
                        :error-messages="errors"
                        :disabled="!pedido.tipo_tolerancia"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="tolerância máxima"
                      :rules="{
                        min_value:
                          regras_tolerancia[pedido.tipo_tolerancia].min,
                        max_value:
                          regras_tolerancia[pedido.tipo_tolerancia].max,
                      }"
                    >
                      <v-text-field
                        type="number"
                        :min="regras_tolerancia[pedido.tipo_tolerancia].min"
                        :max="regras_tolerancia[pedido.tipo_tolerancia].max"
                        :label="`Tolerância ${getNameById(
                          pedido.tipo_tolerancia
                        )} Para Cima`"
                        v-model="pedido.tolerancia_max"
                        :error-messages="errors"
                        :disabled="!pedido.tipo_tolerancia"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="12" md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Tipo de Operação"
                      rules="required"
                    >
                      <v-autocomplete
                        label="Tipo de Operação"
                        item-text="descricao"
                        required
                        item-value="id"
                        :items="tiposOperacao"
                        v-model="pedido.tipo_operacao"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" sm="6" md="2" v-if="temPedidoASerDistribuido">
                    <v-text-field
                      type="number"
                      label="Quantidade Total (Ton)"
                      v-model="pedidoASerDistribuido.quantidade"
                      :disabled="true"
                    />
                  </v-col>
                  <v-col cols="6" sm="6" md="2" v-if="temPedidoASerDistribuido">
                    <v-text-field
                      type="number"
                      label="Quantidade Restante (Ton)"
                      v-model="pedidoASerDistribuido.quantidade_restante"
                      :disabled="true"
                    />
                  </v-col>
                </v-row>
                <replicacao-transportadora
                  ref="replicacaoTransportadora"
                  :pedido="pedidoASerDistribuido"
                  :temPedidoASerDistribuido="temPedidoASerDistribuido"
                  @handleSuspender="suspender"
                  v-if="temPedidoASerDistribuido"
                />
                <replicacao-cliente
                  ref="replicacaoCliente"
                  :navioFoiSelecionado="navioFoiSelecionado"
                  :pedido="pedido"
                  v-else
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <buttons-cadastro @close="close" @save="save" :disabled="invalid" />
        </v-card-actions>
      </validation-observer>
    </div>
    <v-dialog v-model="showDistribuicoes" persistent>
      <v-data-table
        :headers="headersDistribuicoes"
        :items="distribuicoes"
        class="border table__distribuicoes"
        @dblclick:row="handleSelectDistribuicao"
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="d-flex flex-column">
            <v-toolbar-title>Selecione uma distribuição</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <span>Sem distribuições cadastradas</span>
        </template>
      </v-data-table>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import ButtonsCadastro from '../../ButtonsCadastro/ButtonsCadastro.vue'
import ReplicacaoCliente from './ReplicacaoCliente.vue'
import ReplicacaoTransportadora from './ReplicacaoTransportadora.vue'
import GateAPI from '@/services/tenants/gates'
import DistribuicaoAPI from '@/services/distribuicao'
import Suspensao from '@/components/suspensao/suspensao.vue'
import { Sequences } from '@/utils/permissions'
import { Tolerancia, getNameById } from '@/utils/tolerancia'
// import { defineRule } from 'vee-validate'
// import { required, max, min } from 'vee-validate/rules'
// defineRule('required', required)
// defineRule('max', max)
// defineRule('min', min)

export default {
  name: 'CadastroPedido',

  components: {
    ButtonsCadastro,
    ReplicacaoCliente,
    ReplicacaoTransportadora,
    Suspensao,
  },

  props: {
    pedidoEdit: {
      type: Object,
      default: () => ({}),
    },
    pedidoASerDistribuido: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isEdit: false,
      overlay: false,
      delay: 300,
      showDistribuicoes: false,
      headersDistribuicoes: [
        {
          text: 'Terminal',
          align: 'start',
          value: 'terminal.descricao',
        },
        { text: 'Fornecedor', value: 'fornecedor.trade_name' },
        { text: 'Cliente', value: 'cliente.trade_name' },
        { text: 'Munícipio', value: 'cidade' },
        { text: 'D.I.', value: 'di' },
        { text: 'N. Pedido Imp.', value: 'nPedidoImp' },
        { text: 'Shipment', value: 'shipment' },
        { text: 'Produto', value: 'produto.descricao' },
        { text: 'Sub Produto', value: 'subProduto.descricao' },
        { text: 'Quantidade (TON)', value: 'quantidade' },
        { text: 'Restante', value: 'quantidade_restante' },
      ],
      distribuicoes: [],
      embalagens: [],
      produtos: [],
      subProdutos: [],
      navios: [],
      gates: [],

      pedido: {
        data_inicio: '',
        data_fim: '',
        fornecedor: {},
        gate: {},
        tolerancia_min: 0,
        tolerancia_max: 0,
        tipo_operacao: 0,
        tipo_tolerancia: 1,
      },
      pedidoSuspensao: {},
      tiposTolerancia: [],
      regras_tolerancia: {},
    }
  },

  computed: {
    ...mapState('patio', ['tiposFrete', 'tiposOperacao']),
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('auth', ['empresaAtual']),
    permission() {
      return Sequences.Pedidos.toString()
    },
    fornecedoresGate() {
      return this.gate.fornecedores || []
    },

    naviosComOpcaoNenhum() {
      return [{ descricao: 'Nenhum', id: null }, ...this.navios]
    },

    navioFoiSelecionado() {
      return typeof this.pedido.navio === 'number' && null >= 0
    },

    temPedidoASerDistribuido() {
      return Object.keys(this.pedidoASerDistribuido).length > 0
    },
  },

  async created() {
    this.tiposTolerancia = Object.entries(Tolerancia).map((t) => t[1])
    this.regras_tolerancia[Tolerancia.Perc.id] = {
      min: 0,
      max: 100,
    }
    this.regras_tolerancia[Tolerancia.Bruto.id] = {
      min: 0,
      max: 2500,
    }

    if (!this.empresaAtual.gate) {
      this.gates = await GateAPI.getByParams({
        fornecedor_tenant: this.empresaAtual.public_id,
      })
    } else {
      await this.getFornecedores()
    }

    const today = new Date().toLocaleDateString('pt-BR')
    const [dia, mes, ano] = today.split('/')

    this.pedido.data_inicio = `${ano}-${mes}-${dia}`
    this.pedido.data_fim = `${ano}-${mes}-${dia}`

    await this.getTiposFrete()
    await this.getTipoOperacao()

    if (this.temPedidoASerDistribuido) {
      await this.setValoresIniciaisDoPedido(this.pedidoASerDistribuido)
      this.pedido.numero_pedido = this.pedidoASerDistribuido.numero_pedido
    }
    this.pedido.tipo_operacao = { id: 1, descricao: 'Carga' }
  },

  methods: {
    ...mapActions('patio', [
      'addPedido',
      'getTipoOperacao',
      'getTiposFrete',
      'getNavios',
      'editPedido',
    ]),
    ...mapActions('fornecedores', ['getFornecedores']),
    ...mapActions('template', ['errorMessage', 'successMessage']),
    getNameById(item) {
      if (item == null) return ''
      return getNameById(item)
    },
    suspender(item) {
      this.pedidoSuspensao = item
      this.$nextTick(() => {
        this.$refs['suspensao'].open()
      })
    },
    getLabelEmpresa(item) {
      return `${item.business_name} ${item.cnpj}`
    },

    async setFornecedorPedido() {
      const fornecedor = this.pedido.gate.fornecedores.find(
        (f) => f.fornecedor_id === this.empresaAtual.public_id
      )
      this.pedido.fornecedor = fornecedor
      await this.setOpcoesDoFornecedor(fornecedor)
    },

    async setOpcoesDoFornecedor(fornecedor) {
      this.produtos = fornecedor.produtos
      this.embalagens = fornecedor.embalagens
      this.navios = await this.getNavios({
        fornecedor: fornecedor.public_id,
      })
      //   this.gates = await GateAPI.getByParams({
      //     fornecedor: fornecedor.public_id,
      //   })
    },

    async setValoresIniciaisDoPedido(pedido) {
      const {
        fornecedor,
        gate,
        tipoFrete,
        navio,
        subProduto,
        embalagem,
      } = pedido

      await this.setOpcoesDoFornecedor(fornecedor)

      this.pedido = {
        ...pedido,
        gate,
        tipoFrete: tipoFrete.id,
        navio: navio ? navio.id : null,
        subProduto: subProduto.id,
        embalagem: embalagem.id,
      }
    },

    async handleFornecedorSelecionado(fornecedor) {
      if (!fornecedor) return

      await this.setOpcoesDoFornecedor(fornecedor)
    },

    validate() {
      this.$refs.observer.validate()
      const isValid = this.$refs.observer.flags.valid

      return isValid
    },

    close() {
      this.$emit('close')
    },

    async save() {
      if (this.validate()) {
        const data = {
          ...this.pedido,
          fornecedor: this.pedido.fornecedor.public_id,
          cliente: this.pedido.cliente?.public_id,
          proprietario: this.empresaAtual.public_id,
          gate: this.empresaAtual.gate
            ? this.empresaAtual.public_id
            : this.pedido.gate.public_id,
          produto: this.pedido.produto.id,
          tipo_operacao: this.pedido.tipo_operacao.id,
        }

        delete data.to
        if (data.public_id) {
          let pedido_a_editar = { ...data }
          delete pedido_a_editar.transportadora
          pedido_a_editar.terminal = data.terminal.public_id
          // this.editPedido(pedido_a_editar)
        }

        if (this.temPedidoASerDistribuido) {
          delete data.id
          delete data.public_id
          delete data.quantidade_restante

          data['pedido_pai'] = this.pedidoASerDistribuido.public_id
        }
        await this.distribuirPedido(data)

        // if (this.isEdit) {
        //   try {
        //     setTimeout(async () => {
        //       await this.editPedido({ ...data, ...this.replicacoes[0] })
        //       this.successMessage('Pedido atualizado com sucesso!')
        //     }, this.delay)
        //   } catch (e) {
        //     this.errorMessage(e.response.data)
        //   }
        // } else {
        //   await this.distribuirPedido(data)
        // }
      }
    },

    async distribuirPedido(data) {
      this.overlay = true

      let pedidos = []
      const { replicacaoTransportadora, replicacaoCliente } = this.$refs
      if (!this.temPedidoASerDistribuido) {
        pedidos = replicacaoCliente.replicacoes.map((replicacao) => {
          return Object.assign({}, data, replicacao)
        })
      } else {
        await replicacaoTransportadora.handleSave()
        pedidos = replicacaoTransportadora.replicacoes.map((replicacao) => {
          return Object.assign({}, data, replicacao)
        })
      }

      const pedidos_a_cadastrar = pedidos.filter((item) => !item.public_id)
      const pedidos_a_editar = pedidos.filter((item) => item.public_id)
      setTimeout(async () => {
        try {
          if (pedidos_a_cadastrar.length > 0) {
            await this.addPedido(pedidos_a_cadastrar)
          }
          if (pedidos_a_editar.length > 0) {
            for (const pedido of pedidos_a_editar) {
              await this.editPedido(pedido)
            }
          }
          this.successMessage('Pedido realizado com sucesso!')
        } catch (error) {
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        } finally {
          this.close()
          this.overlay = false
        }
      }, this.delay)
    },

    async handleSelectDistribuicao(_, { item: distribuicao }) {
      const { subProduto, embalagem, nPedidoImp } = distribuicao

      const produto = this.produtos.find(
        (produto) => produto.id === distribuicao.produto.id
      )

      this.pedido = Object.assign({}, this.pedido, {
        produto,
        subProduto: subProduto.id,
        embalagem: embalagem.id,
        numero_pedido_importacao: nPedidoImp,
        distribuicao: distribuicao.id,
      })

      this.showDistribuicoes = false
    },

    async getDistribuicoes() {
      const { navio } = this.pedido

      if (this.navioFoiSelecionado) {
        this.overlay = true

        setTimeout(async () => {
          this.distribuicoes = await DistribuicaoAPI.getDistribuicao(navio, {
            fornecedor: this.pedido.fornecedor.public_id,
          })

          this.showDistribuicoes = true
          this.overlay = false
        }, this.delay)
      }
    },
  },

  watch: {
    'pedido.produto': function (produto) {
      this.subProdutos = produto.subprodutos
    },
  },
}
</script>

<style>
.table__distribuicoes tr {
  cursor: pointer;
}
</style>
